


























































import { ChoiceWrapper } from '@/lib-on-fhir';
import ExplorerResolver from '@/lib-on-fhir/vue-example/resource-explorer/ExplorerResolver.vue';
import { config } from '@/services/config';
import Vue from 'vue';

export default Vue.extend({
    props: {
        element: {
            type: ChoiceWrapper,
        },
        depth: {
            type: Number,
            default: 0,
        },
    },

    components: { ExplorerResolver },

    computed: {
        showFull(): boolean {
            return config.showDebug;
        },
    },

    data: () => ({
        ExplorerResolver,
    }),

    methods: {
        changeTypeIndex(event: Event) {
            const index = (event.target as HTMLSelectElement).selectedIndex;
            const type = this.element.$type.allowedTypes[index];
            console.log('Change:', index, '->', type);
            this.element.changeType(type);
        },
        deleteElement() {
            this.element.deleteValue();
        },
    },
});
